<template>
  <div class="app-container">
    <div class="titletop">
      <div class="cname mt20">
        <span>客户名称：{{ cname }}</span>
      </div>
      <div class="session1">
        <div class="detail theme">
          <div class="box">
            人脸打卡：<span>{{ faceLabel }}</span>
            <el-button class="btn" @click="faceConfig.visible = true" type="text" size="small">修改</el-button>
          </div>
        </div>
        <div class="detail theme">
          <div class="box">
            广告机管理：<span>{{ machineLabel }}</span>
            <el-button class="btn" @click="machineConfig.visible = true" type="text" size="small">修改</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 人脸打卡 -->
    <el-dialog
      class="config-dialog"
      width="600px"
      title="修改"
      :visible.sync="faceConfig.visible"
      destroy-on-close
      @open="handleFaceDialogOpen"
    >
      <div class="theme-wrap">
        <span>人脸打卡：</span>
        <el-radio v-for="item in list" :key="item.value" v-model="faceConfig.val" :label="item.value"
          >{{ item.label }}
        </el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="faceConfig.visible = false">取 消</el-button>
        <el-button type="primary" :loading="faceConfig.loading" @click="handleFaceDialog">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 广告机管理 -->
    <el-dialog
      class="config-dialog"
      width="600px"
      title="修改"
      :visible.sync="machineConfig.visible"
      destroy-on-close
      @open="handleMachineDialogOpen"
    >
      <div class="theme-wrap">
        <span>广告机管理：</span>
        <el-radio v-for="item in list" :key="item.value" v-model="machineConfig.val" :label="item.value"
          >{{ item.label }}
        </el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="machineConfig.visible = false">取 消</el-button>
        <el-button type="primary" :loading="machineConfig.loading" @click="handleMachineDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSwitchConfigs, switchFra, switchInfraredNavAD } from 'src/api/legacy'

export default {
  data() {
    return {
      cid: this.$route.query.cid,
      cname: this.$route.query.cname,

      faceVal: undefined,
      machineVal: undefined,

      faceConfig: {
        visible: false,
        val: undefined,
        loading: false,
      },
      machineConfig: {
        visible: false,
        val: undefined,
        loading: false,
      },
      list: [
        {
          label: '开',
          value: 1,
        },
        {
          label: '关',
          value: 0,
        },
      ],
    }
  },
  computed: {
    faceLabel() {
      const elem = this.list.find((item) => item.value === this.faceVal)
      return elem ? elem.label : ''
    },
    machineLabel() {
      const elem = this.list.find((item) => item.value === this.machineVal)
      return elem ? elem.label : ''
    },
  },

  mounted() {
    this.initData()
  },

  methods: {
    initData() {
      getSwitchConfigs({ cid: this.cid }).then((res) => {
        if (res && res.data) {
          this.faceVal = res.data.fra
          this.machineVal = res.data.infraredNavAD
        }
      })
    },
    handleFaceDialogOpen() {
      this.faceConfig.val = this.faceVal
    },
    handleFaceDialog() {
      if (this.faceConfig.val === this.faceVal) {
        this.faceConfig.visible = false
        return
      }
      var self = this
      this.faceConfig.loading = true

      switchFra({
        cid: this.cid,
      }).then(function (res) {
        if (res) {
          self.$message({
            message: '操作成功',
            type: 'success',
          })
          self.faceConfig.loading = false
          self.faceConfig.visible = false
          self.initData()
        }
      })
    },
    handleMachineDialogOpen() {
      this.machineConfig.val = this.machineVal
    },
    handleMachineDialog() {
      if (this.machineConfig.val === this.machineVal) {
        this.machineConfig.visible = false
        return
      }
      var self = this
      this.machineConfig.loading = true

      switchInfraredNavAD({
        cid: this.cid,
      }).then(function (res) {
        if (res) {
          self.$message({
            message: '操作成功',
            type: 'success',
          })
          self.machineConfig.loading = false
          self.machineConfig.visible = false
          self.initData()
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.navtitle {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  font-size: 14px;
}
.session1 {
  padding: 40px 0 20px;
  font-size: 14px;
}
.session1 .detail {
  padding-bottom: 16px;
}
.cname span {
  font-size: 16px;
}
</style>